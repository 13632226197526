import React, { useState, useEffect } from "react";
import MainLogo from "../images/Wordmark-Logo-Blue.svg";
import Favicon from "../images/Favicon.png";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Login } from "faunadb";

const IndexPage = () => {
  //
  const date = new Date();

  ///
  /*Start FaunaDB*/
  /*Imports*/
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  const client = new faunadb.Client({
    secret: "fnAFYC386qAAQC4nGVmj_Ct4UQp-My63biKkpviJ",
    //secret: "fnAEzBL0dOACT6zMMFftm4TpL9YnKvLh4_xzIRrZ",/Main-opengate secret/
  });
  const faunaClient = new faunadb.Client({
    secret: "fnAFYC5vuiAAQHKl0jbZM6ySKjTjhR80IwdpNLy0",
    // GOTTA ADD DB SWITCH LOGIC FOR OTHER SUPPLIERS //
  });

  var faunaClientTest = new faunadb.Client({
    secret: "fnAFYC5_wxAAQEBIW4p4gsDl6ScyWmpLdyj3PPd_",
    // GOTTA ADD DB SWITCH LOGIC FOR OTHER SUPPLIERS //
  });

  const [loginResponse, setloginResponse] = useState(null);
  const [activeUser, setactiveUser] = useState("");
  const [areCredentialsOk, setareCredentialsOk] = useState(null);
  const [userCredentials, setuserCredentials] = useState(null);
  const [loginScreen, setloginScreen] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    window.localStorage.clear();
    window.localStorage.setItem(
      "USER_CREDENTIALS",
      JSON.stringify(userCredentials)
    );

    if (userCredentials !== null) {
      getUser();
    } else console.log();
  }, [userCredentials]);

  //
  //
  //
  function handleChange(e) {
    setloginScreen({
      ...loginScreen,
      [e.target.name]: e.target.value,
    });
  }
  ///
  //
  const logon = () => {
    loginMain();
  };

  //
  //
  const getUser = async () => {
    {
      const loggedInUser = await client.query(
        q.Map(
          q.Paginate(
            q.Match(
              q.Index("users_by_reference"),
              userCredentials.instance.value.id
            )
          ),
          q.Lambda((x) => q.Get(x))
        )
      );

      window.localStorage.setItem(
        "ACTIVE_USER",
        JSON.stringify(loggedInUser.data)
      );
      window.location.href = `./select-supplier`;
    }
  };

  //
  ///
  //

  const loginMain = async () => {
    {
      await client
        .query(
          q.Login(
            q.Match(q.Index("users_by_email"), loginScreen.email.toLowerCase()),
            {
              password: loginScreen.password,
            }
          )
        )
        .then((ret) => {
          setareCredentialsOk(true);
          setuserCredentials(ret);

          //  window.location.href = `./select-supplier`;

          //window.location.href = `./app/?key=${ret.secret}&user=${ret.ref.value.id}`;
        })

        .catch((err) => {
          setareCredentialsOk(false);
          console.error(
            "Error: [%s] %s: %s",
            err.name,
            err.message,
            err.errors()[0].description
          );
        });
    }
  };

  //
  //
  //
  //
  const agentCallIn = async () => {
    const IVRtime = new Date().toGMTString();
    let response = await fetch(
      "/.netlify/functions/agent_call_in",

      {
        method: "POST",
        body: JSON.stringify({
          message: "Testing agent call in brah",
          date: IVRtime,
        }),
      }
    );
  };
  //
  //
  //
  const testArray = [
    "question1" + "$yes",
    "question2" + "$no",
    "question3" + "$yes",
  ];
  //
  testArray.push("question4" + "$yes");
  testArray.push("question5" + "$yes");
  //
  console.log(testArray);
  //
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>OpenGate | Third Party Verification</title>

        <meta
          name="description"
          content="Your new moden third party verification option for all your needs."
        />
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="website"></meta>
        <meta
          property="og:title"
          content=" OpenGate | Third Party Verification"
        ></meta>
        <meta
          property="og:description"
          content="Your new moden third party verification option for all your needs."
        ></meta>
        <meta
          property="og:site_name"
          content=" OpenGate | Third Party Verification"
        ></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta
          name="twitter:description"
          content="Your new moden third party verification option for all your needs."
        ></meta>
        <meta
          name="twitter:title"
          content="OpenGate | Third Party Verification"
        ></meta>
        <link rel="icon" href={Favicon} />
      </Helmet>

      <div class="bg-[radial-gradient(ellipse_at_bottom_right,_var(--tw-gradient-stops))] from-indigo-200 via-indigo-400 to-indigo-600 h-screen animate-gradient-y">
        <div class="flex min-h-full items-center justify-center lg:py-12 px-4 sm:px-6 lg:px-8">
          {areCredentialsOk === false ? (
            <div class="animate-fade p-10 border-2 border-red-600 rounded-xl shadow bg-white w-full max-w-2xl space-y-8">
              <div>
                <img
                  class="mx-auto h-30 w-auto"
                  src={MainLogo}
                  alt="Your Company"
                ></img>
                <h2 class="mt-6 text-center text-2xl font-semibold tracking-tight text-gray-500">
                  Sign in to your account
                </h2>
              </div>

              <input type="hidden" name="remember" value="true"></input>
              <div class="-space-y-px rounded-md shadow-sm">
                <div>
                  <label for="email-address" class="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autocomplete="email"
                    onChange={handleChange}
                    required
                    autofocus
                    class="relative block w-full appearance-none rounded-none rounded-t-md border border-red-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Email address"
                  ></input>
                </div>
                <div>
                  <label for="password" class="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    onChange={handleChange}
                    required
                    class="relative block w-full appearance-none rounded-none rounded-b-md border border-red-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Password"
                  ></input>
                </div>
              </div>
              <div class="hidden flex items-center justify-between">
                <div class="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  ></input>
                  <label
                    for="remember-me"
                    class="ml-2 block text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div class="text-sm">
                  <a
                    href="#"
                    class="font-medium text-blue-800 hover:text-blue-700"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => logon()}
                  class="group relative flex w-full justify-center rounded-md   bg-red-600 py-4 px-4 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-bg-blue-700 focus:ring-offset-2"
                >
                  Please Check Your Credentials
                </button>
              </div>
              <div>
                <button
                  type="button"
                  class="hidden flex mx-auto text-sm text-gray-400 text-center hover:underline"
                >
                  Don't have an account? Sign up
                </button>
              </div>
            </div>
          ) : (
            <div class="animate-fade p-10 rounded-xl shadow bg-white w-full max-w-2xl space-y-8">
              <div>
                <img
                  class="mx-auto h-30 w-auto"
                  src={MainLogo}
                  alt="Your Company"
                ></img>
                <h2 class="mt-6 text-center text-2xl font-semibold tracking-tight text-gray-500">
                  Sign in to your account
                </h2>
              </div>

              <input type="hidden" name="remember" value="true"></input>
              <div class="-space-y-px rounded-md shadow-sm">
                <div>
                  <label for="email-address" class="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autocomplete="email"
                    onChange={handleChange}
                    required
                    autofocus
                    class="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Email address"
                  ></input>
                </div>
                <div>
                  <label for="password" class="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    onChange={handleChange}
                    required
                    class="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Password"
                  ></input>
                </div>
              </div>

              <div class="hidden lg:hidden items-center lg:justify-between">
                <div class="justify-center flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  ></input>
                  <label
                    for="remember-me"
                    class="ml-2 block text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div class="text-center pt-2 text-sm">
                  <a
                    href="#"
                    class="font-medium text-blue-800 hover:text-blue-700"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>

              <div>
                {areCredentialsOk === true ? (
                  <button
                    type="button"
                    disabled
                    class="group relative flex w-full justify-center rounded-md   bg-green-600 py-4 px-4 text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-bg-blue-700 focus:ring-offset-2"
                  >
                    <svg
                      role="status"
                      class="inline mr-3 w-5 h-5 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="blue-700"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Logging In
                  </button>
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={() => logon()}
                      class="hidden group relative lg:flex w-full justify-center rounded-md bg-blue-800 py-4 px-4 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-bg-blue-700 focus:ring-offset-2"
                    >
                      Sign in
                    </button>
                    <button
                      type="button"
                      onMouseDown={() => logon()}
                      class="flex lg:hidden group relative flex w-full justify-center rounded-md bg-blue-800 py-4 px-4 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-bg-blue-700 focus:ring-offset-2"
                    >
                      Sign in
                    </button>
                  </>
                )}
              </div>
              <div>
                <button
                  type="button"
                  class="hidden flex mx-auto text-sm text-gray-400 text-center hover:underline"
                >
                  Don't have an account? Sign up
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default IndexPage;

/* Vendor admin can create campaigns, products scripts, and users security, cannot create orders */
/* vendor manager can create managers and agents and sell products*/
